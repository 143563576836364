<template>
  <header id="page-topbar" data-testid="navbar-wrapper">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo">
            <span class="logo-sm">
              <img src="@/assets/images/icon.svg" height="25" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.svg" height="25" />
            </span>
          </router-link>
        </div>
        <button id="vertical-menu-btn" data-testid="toggle-menu" type="button"
          class="btn btn-sm px-3 font-size-16 header-item" aria-label="Alternar menu" @click="toggleMenu">
          <i class="fa fa-fw fa-bars" aria-hidden="true"></i>
        </button>
      </div>
      <div class="logotipo">
        <img src="@/assets/images/logo_general.svg" />
      </div>

      <div class="d-flex">
        <b-dropdown right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="bx bx-bell" size="lg" />
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notificações</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">Ver todas</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px"> </simplebar>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <img class="avatar" src="@/assets/images/iconeUser.svg" alt="avatar do usuário" />
            <span class="d-none d-xl-inline-block ml-1 mr-1" style="color: #00ab58">
              {{
                `${user.nomeGestorAssociado
                  ? '(Gestor: ' + user.nomeGestorAssociado + ') '
                  : ''
                }`
              }}
            </span>
            <span class="d-none d-xl-inline-block ml-1 mr-1">
              {{ user.nome }}
            </span>
            <i class="bx bx-chevron-down font-size-16 align-middle mr-1"></i>
          </template>

          <b-dropdown-item>
            <router-link tag="span" to="/profile">
              <i class="bx bx-user font-size-16 align-middle mr-1" style="color: black"></i>
              <span style="color: black">{{ $t('navbar.perfil') }}</span>
            </router-link>
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <router-link :to="{ name: 'logout' }" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t('navbar.logout') }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getAuth } from '../features/auth/auth.helpers';
import simplebar from 'simplebar-vue';

const { user, logotipo, acl } = getAuth();
const { mapState } = createNamespacedHelpers('auth');

export default {
  name: 'locale-changer',
  data() {
    return {
      userLogin: user.login,
      logo: logotipo,
      langs: [
        { lang: 'pt_br', label: 'PT' },
        { lang: 'en_us', label: 'EN' }
      ],
      acl: acl
    };
  },

  components: { simplebar },

  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    hasPermission(item, permissions) {
      return (
        item.name !== undefined &&
        permissions !== undefined &&
        permissions.includes(item.name)
      );
    }
  },

  computed: {
    ...mapState(['user']),
    perfilItem: () => {
      return {
        id: 2,
        label: 'Perfil',
        name: 'perfil_edit',
        link: '/profile'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.logotipo {
  img {
    max-height: 68px;

    @media screen and (max-width: 600px) {
      max-height: 41px;
    }
  }
}

.avatar {
  width: 20px;
}

.locale-changer {
  margin-top: 23px;
  width: 40px;
}

.select-locale-changer {
  width: 45px;
  height: 20px;
  border: none;
  background: transparent;
}
</style>
